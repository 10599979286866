export const useActiveRetailChainAndPosStore = defineStore(
  "active-retail-chain-and-pdv",
  {
    state: () => ({
      pos: null,
      retail_chain: null,
      isBkoManager: false,
    }),
    actions: {
      setValues(retail_chain: any, pos: any) {
        this.pos = pos;
        this.retail_chain = retail_chain;
      },
      setIsBkoManager(isBkoManager: boolean) {
        this.isBkoManager = isBkoManager;
      },
    },
    getters: {
      getPosCode: (state) => {
        return state.pos?.code || "Não informado";
      },
      getPos: (state) => {
        return state.pos;
      },
      getRetailChainName: (state) => {
        return state.retail_chain?.name || "Não informado";
      },

      isRetailChainAndPosSelect: (state) => {
        return !!state.retail_chain?.name && !!state.pos?.code;
      },
    },
    persist: {
      storage: persistedState.sessionStorage,
    },
  },
);
